<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-alert
        :value="month !== monthOf"
        color="info"
        icon="info"
      >
        Previous month AI calculations do not reflect the actual monthly AI that has been disbursed
        because subscribers may have changed their mobile plan from the original activation plan.
      </v-alert>
      <v-toolbar
        dense
        card
      >
        <v-menu
          v-model="menu"
          lazy
          close-on-content-click
          transition="scale-transition"
          full-width
          min-width="290px"
        >
          <v-text-field
            slot="activator"
            v-model="monthOf"
            prepend-icon="event"
            readonly
          />
          <v-date-picker
            v-model="monthOf"
            :max="maxDate"
            :allowed-dates="allowedMonths"
            type="month"
            year-icon="date_range"
          />
        </v-menu>
        <v-spacer />
      </v-toolbar>
      <v-card-title>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          solo-inverted
          clearable
          box
          hide-details
        />
      </v-card-title>
      <v-card-title>
        <h3 class="primary--text">
          1st Activation Incentive (40%)
        </h3>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :pagination.sync="pagination"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <td
            v-if="props.item.plan === 'ONEFamily'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
  &nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td class="text-xs-center primary--text">
              {{ ONEFamily.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right primary--text">
              1st AI:
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(ONEFamily.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right">
              Total 1st AI:
            </td>
            <td
              class="text-xs-center primary"
            >
              {{ getRm( Number(ONEFamily.incentive) ) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="primary" />
      <v-card-title>
        <h3 class="primary--text">
          2nd Activation Incentive (30%)
        </h3>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers2"
        :items="items2"
        :loading="loading"
        :pagination.sync="pagination2"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <td
            v-if="props.item.plan === 'ONEFamily'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
  &nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td class="text-xs-center primary--text">
              {{ ONEFamily_2.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right primary--text">
              2nd AI:
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(ONEFamily_2.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right">
              Total 2nd AI:
            </td>
            <td
              class="primary text-xs-center"
            >
              {{ getRm(Number(ONEFamily_2.incentive)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <v-divider class="primary" />
      <v-card-title>
        <h3 class="primary--text">
          3rd Activation Incentive (30%)
        </h3>
        <v-spacer />
      </v-card-title>
      <v-data-table
        :headers="headers3"
        :items="items3"
        :loading="loading"
        :pagination.sync="pagination3"
        :search="search"
        class="px-1 pb-1"
        item-key="name"
      >
        <template
          slot="items"
          slot-scope="props"
        >
          <td class="text-xs-left">
            {{ props.item.date }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td
            v-if="withCategory"
            class="text-xs-center"
          >
            {{ props.item.category }}
          </td>
          <td
            v-if="props.item.plan === 'ONEFamily'"
            class="text-xs-center"
          >
            <v-icon>mdi-check</v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
  &nbsp;
          </td>
        </template>
        <template slot="no-data">
          {{ noDataText }}
        </template>
        <template slot="no-result">
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >
            {{ noResultText }}
          </v-alert>
        </template>
        <template slot="footer">
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right primary--text">
              Count:
            </td>
            <td class="text-xs-center primary--text">
              {{ ONEFamily_3.count }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right primary--text">
              3rd AI:
            </td>
            <td class="text-xs-center primary--text">
              {{ getRm(Number(ONEFamily_3.incentive)) }}
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td v-if="withCategory">
  &nbsp;
            </td>
            <td class="text-xs-right">
              Total 3rd AI:
            </td>
            <td
              class="primary text-xs-center"
            >
              {{ getRm(Number(ONEFamily_3.incentive)) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
import { getRm } from '@/utils'

const reportTitle = 'ONEfam AI'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
        // const fromDate = DateTime.local(2018, 1, 1).setZone('Asia/Kuala_Lumpur')
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
        const fromDate = DateTime.local().setZone('Asia/Kuala_Lumpur').plus({ months: -3 })

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      blank: { count: 0, incentive: 0 },
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: reportTitle, disabled: true,
        },
      ],
      expand: true,
      getRm: getRm,
      items: [],
      items2: [],
      items3: [],
      counter: [],
      counter2: [],
      counter3: [],
      loading: false,
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      noDataText: null,
      noResultText: 'No data that fits that criteria.',
      pagination: {
        descending: true,
        sortBy: 'name',
      },
      pagination2: {
        descending: true,
        sortBy: 'name',
      },
      pagination3: {
        descending: true,
        sortBy: 'name',
      },
      search: '',
      title: reportTitle,
      totalIn: 0,
      totalOut: 0,
    }
  },
  computed: {
    withB49: function () {
      return this.monthOf >= '2021-05'
    },
    headers: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'ONEFam',
        value: 'ONEFamily',
      })
      return headers
    },
    headers2: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'ONEFam',
        value: 'ONEFamily',
      })

      return headers
    },
    headers3: function () {
      const headers = []
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Entry',
        value: 'date',
      })
      headers.push({
        align: 'left',
        sortable: true,
        text: 'Msisdn',
        value: 'msisdn',
      })
      this.withCategory && headers.push({
        align: 'center',
        sortable: false,
        text: 'Category',
        value: 'category',
      })
      headers.push({
        align: 'center',
        sortable: false,
        text: 'ONEFam',
        value: 'ONEFamily',
      })
      return headers
    },
    withCategory: function () {
      return this.monthOf >= '2020-01'
    },
    ONEFamily: function () {
      return this.counter.ONEFamily ? this.counter.ONEFamily : this.blank
    },
    ONEFamily_2: function () {
      return this.counter2.ONEFamily ? this.counter2.ONEFamily : this.blank
    },
    ONEFamily_3: function () {
      return this.counter3.ONEFamily ? this.counter3.ONEFamily : this.blank
    },
  },
  watch: {
    monthOf: function (val) {
      this.getFamilyPlanAI(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getFamilyPlanAI (month) {
      const params = createGetParams({
        month: month,
      })
      this.loading = true
      this.items = this.items2 = this.items3 = []
      this.counter = this.counter2 = this.counter3 = []
      this.$rest.get('getFamilyPlanAI2.php', params)
        .then(function (response) {
          if (response.data.items) this.items = response.data.items
          if (response.data.counter) this.counter = response.data.counter
          if (response.data.items2) this.items2 = response.data.items2
          if (response.data.counter2) this.counter2 = response.data.counter2
          if (response.data.items3) this.items3 = response.data.items3
          if (response.data.counter3) this.counter3 = response.data.counter3
          this.noDataText = "There's nothing to show."
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
  },
}
</script>
